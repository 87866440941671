<template>
  <div>
    <v-row>
      <v-col cols="12" class="pa-0 ma-0">
        <v-img
          :src="
            form.knowledgeImg ? form.knowledgeImg : '@/assets/elearning.png'
          "
          width="414px"
          height="231px"
          style="
            display: block;
            margin-left: auto;
            margin-right: auto;
            object-fit: cover;
          "
        />
      </v-col>
      <v-col cols="8">
        <span class="fn12 align-center" style="color: #06204b">
          {{
            form.type === "video"
              ? "Let’s start this video and you will earn"
              : ""
          }}
        </span>
      </v-col>
      <v-col cols="1" class="px-0">
        <img
          src="@/assets/BI.png"
          align="right"
          justify="right"
          style="width: 24px; height: 24px"
        />
      </v-col>
      <v-col cols="3">
        <span class="fn12-weight7 align-center" style="color: #1a347f"
          >{{ form.point }} Points</span
        >
      </v-col>
    </v-row>
    <v-divider class="my-2"></v-divider>
    <div style="color: #06204b" class="fn20-weight7">
      {{ form.knowledge_name }}
    </div>
    <span style="word-break: break-word; font-weight: 700" class="fn12">
      {{
        form.decoration
          ? form.decoration
          : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dui non mauris lobortis congue. Ut eu dui vitae praesent dolor nulla id. Cursus placerat amet tortor tincidunt mauris quis. Malesuada enim in aliquet ornare tristique. Id enim hac facilisi vitae at augue venenatis, eleifend. Sagittis risus nunc odio diam facilisis amet. Sed tincidunt fusce nullam volutpat sapien. Morbi quam nec bibendum pharetra elit interdum scelerisque. Sem aliquam, sapien, accumsan turpis libero aliquam risus. Posuere praesent sit metus nibh eget posuere sit."
      }}
    </span>
    <p
      class="mt-4 text-decoration-underline fn14-weight7"
      style="color: #06204b"
    >
      Terms & conditions
    </p>
    <v-btn
      block
      color="#1A347F"
      elevation="2"
      large
      outlined
      x-large
      class="mt-8 rounded-lg"
      style="color: #1a347f"
      @click="cancel"
    >
      <span class="font">Back</span>
    </v-btn>
  </div>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      categoryName: "",
      categoryDescription: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
      },
      checkValidate: true,
      lazy: false,
      user: "",

      nexId: "",
      form: {},
    };
  },
  created() {
    const urlParams = this.$route.query;
    console.log("urlParams", urlParams);
    this.nexId = urlParams.reward;
    this.getOneNex();
  },
  methods: {
    async getOneNex() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/knowledge/${this.nexId}
          `
      );
      console.log("getOneKnowledge", response.data);
      this.form = response.data.data;
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageNexGardFamily");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("userBiData"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          category: this.categoryName,
          description: this.categoryDescription,
          activeFlag: this.activeFlag,
          categoryImg: this.pic1,
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/categories`,
          data,
          auth
        );
        console.log("createBrand", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างหมวดหมู่สินค้าสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageCategory");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.font {
  font-family: "OpenSansBold" !important;
}
</style>
